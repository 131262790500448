
.nav-container > a, .nav-container > button {
    text-transform: uppercase;
    font-size: 1.3em;
    letter-spacing: 0.1em;
    padding: 1em 0;
    margin-right: 0.5em;
    margin-bottom: 0.1em;
    border-bottom: 4px solid var(--dark-color);
}

.nav-container > a:hover, .nav-container > button:hover {
    border-bottom: 4px solid var(--light-color);
}

a.nav-button, button.nav-button,
a.nav-button:hover, button.nav-button:hover {
    color: white !important;
}

.nav-shadows {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    position: relative;
    -webkit-box-shadow: inset 0 21px 12px -13px rgba(0,0,0,0.27), inset 0 -21px 12px -13px rgba(0,0,0,0.27);
    -moz-box-shadow: inset 0 21px 12px -13px rgba(0,0,0,0.27), inset 0 -21px 12px -13px rgba(0,0,0,0.27);
    box-shadow: inset 0 21px 12px -13px rgba(0,0,0,0.27), inset 0 -21px 12px -13px rgba(0,0,0,0.27);
}

/*.nav-shadows.order-last {
    -webkit-box-shadow: inset 0 21px 12px -13px rgba(0,0,0,0.27);
    -moz-box-shadow: inset 0 21px 12px -13px rgba(0,0,0,0.27);
    box-shadow: inset 0 21px 12px -13px rgba(0,0,0,0.27);
}*/