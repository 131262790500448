/* Base */
html body {
  height: 100% !important;
  box-sizing: border-box !important;
}

/* Variables */
#main-layout.dark-theme {
  --bg-color: #595959;
  --lightest-color: #3d3d3d;
  --darkest-color: #ffffff;
  --secondary-darker-color-second: #ffffff;
  --secondary-darker-color-placeholder: #ffffff;
  --secondary-color-placeholder: #ffffff;
}

#main-layout {
  --bg-color: #f5f5f5;
  --lightest-color: #ffffff;
  --light-color: #03a9f4;
  --dark-color: #007ac1;
  --darkest-color: #000000;

  --primary-color-first: var(--lightest-color);
  --primary-color-second: var(--light-color);
  --primary-color-placeholder: var(--dark-color);
  --primary-color-hover: var(--dark-color);

  --secondary-color-first: var(--light-color);
  --secondary-color-second: var(--lightest-color);
  --secondary-color-placeholder: #888888;
  --secondary-color-hover: #c4c4c4;

  --secondary-darker-color-first: var(--dark-color);
  --secondary-darker-color-second: var(--lightest-color);
  --secondary-darker-color-placeholder: #888888;
  --secondary-darker-color-hover: #c4c4c4;

  background-color: var(--bg-color);
}

/* Primary color */
.primary-color {
  background-color: var(--primary-color-first);
  color: var(--primary-color-second);
}

.primary-color > input,
.primary-color > textarea {
  background-color: var(--primary-color-second);
  color: var(--primary-color-first);
  padding: 0.3em 0.8em;
  border-radius: 0.75em;
}

.primary-color > input::placeholder,
.primary-color > textarea::placeholder {
  color: var(--primary-color-placeholder);
}

a:link, a:visited, a:active, p,
.primary-color > a:link,
.primary-color > a:visited,
.primary-color > a:active,
.primary-color > button,
.primary-color > p {
  color: var(--primary-color-second);
}

a:hover, button:hover,
.primary-color > a:hover,
.primary-color > button:hover {
  color: var(--primary-color-hover);
}

button.custom-button,
a.custom-button,
.primary-color > button.custom-button,
.primary-color > a.custom-button {
  color: var(--primary-color-second);
  border: 2px solid var(--primary-color-second);
  border-radius: 1em;
  padding: 0.2em 0.6em;
}

button.custom-button:hover,
a.custom-button:hover,
.primary-color > button.custom-button:hover,
.primary-color > a.custom-button:hover {
  color: var(--primary-color-hover);
  border: 2px solid var(--primary-color-hover);
  border-radius: 1em;
  padding: 0.2em 0.6em;
}

button.custom-button-inverse,
a.custom-button-inverse,
.primary-color > button.custom-button-inverse,
.primary-color > a.custom-button-inverse {
  color: var(--primary-color-first);
  background-color: var(--primary-color-second);
  border: 2px solid var(--primary-color-second);
  border-radius: 1em;
  padding: 0.2em 0.6em;
}

button.custom-button-inverse:hover,
a.custom-button-inverse:hover,
.primary-color > button.custom-button-inverse:hover,
.primary-color > a.custom-button-inverse:hover {
  color: var(--primary-color-first);
  background-color: var(--primary-color-hover);
  border: 2px solid var(--primary-color-hover);
  border-radius: 1em;
  padding: 0.2em 0.6em;
}

/* Secondary color */
.secondary-color {
  background-color: var(--secondary-color-first);
  color: var(--secondary-color-second);
}

.secondary-color > input,
.secondary-color > textarea {
  color: var(--secondary-color-first);
  background-color: var(--secondary-color-second);
  padding: 0.3em 0.8em;
  border-radius: 0.75em;
}

.secondary-darker-color > input::placeholder,
.secondary-darker-color > textarea::placeholder {
  color: var(--secondary-color-placeholder);
}

.secondary-color > a:link,
.secondary-color > a:visited,
.secondary-color > a:active,
.secondary-color > button,
.secondary-color > p {
  color: var(--secondary-color-second);
}

.secondary-color > a:hover,
.secondary-color > button:hover {
  color: var(--secondary-color-hover);
}

.secondary-color > button.custom-button,
.secondary-color > a.custom-button {
  color: var(--secondary-color-second);
  border: 2px solid var(--secondary-color-second);
  border-radius: 1em;
  padding: 0.2em 0.6em;
}

.secondary-color > button.custom-button:hover,
.secondary-color > a.custom-button:hover {
  color: var(--secondary-color-hover);
  border: 2px solid var(--secondary-color-hover);
  border-radius: 1em;
  padding: 0.2em 0.6em;
}

/* Secondary darker color */
.secondary-darker-color {
  background-color: var(--secondary-darker-color-first);
  color: var(--secondary-darker-color-second);
}

.secondary-darker-color > input,
.secondary-darker-color > textarea {
  color: var(--secondary-darker-color-first);
  background-color: var(--secondary-darker-color-second);
  padding: 0.3em 0.8em;
  border-radius: 0.75em;
}

.secondary-darker-color > input::placeholder,
.secondary-darker-color > textarea::placeholder {
  color: var(--secondary-darker-color-placeholder);
}

.secondary-darker-color > a:link,
.secondary-darker-color > a:visited,
.secondary-darker-color > a:active,
.secondary-darker-color > button,
.secondary-darker-color > p {
  color: var(--secondary-darker-color-second);
}

.secondary-darker-color > a:hover,
.secondary-darker-color > button:hover {
  color: var(--secondary-darker-color-hover);
}

.secondary-darker-color > button.custom-button,
.secondary-darker-color > a.custom-button {
  color: var(--secondary-darker-color-second);
  border: 2px solid var(--secondary-darker-color-second);
  border-radius: 1em;
  padding: 0.2em 0.6em;
}

.secondary-darker-color > button.custom-button:hover,
.secondary-darker-color > a.custom-button:hover {
  color: var(--secondary-darker-color-hover);
  border: 2px solid var(--secondary-darker-color-hover);
  border-radius: 1em;
  padding: 0.2em 0.6em;
}

/* Custom styling */
a, a:link, a:visited, a:hover, a:active, button {
  font-size: 1.1em;
  text-decoration: none;
}

a.block-link, a.block-link:link, a.block-link:visited, a.block-link:hover, a.block-link:active {
  display: block;
  margin-bottom: 1em;
}

button {
  background-color: transparent;
}

button, input, textarea {
  outline: none !important;
  border: 0;
}

input[disabled], textarea[disabled] {
  background-color: var(--dark-color);
  color: #eeeeee;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

p {
  margin: 0;
}

.custom-border {
  border: 2px solid var(--light-color);
  border-radius: 0.4rem;
  padding: 0.5rem;
}

.required::after {
  color: #ea2b49;
  content: "*";
  font-weight: bold;
  margin-left: 0.3em;
  font-size: 1em;
}

p.error-message {
  color: #ea2b49;
  margin-bottom: 1em;
}

p.secondary-error-message {
  color: #ffd39a;
  margin-bottom: 1em;
}

button.red-button {
  color: #ea2b49;
  border-color: #ea2b49;
}

button.red-button:hover {
  color: #992322;
  border-color: #992322;
}

/* Custom utilities */
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.elements-apart {
  display: flex;
  justify-content: space-between;
}

.font-size-small {
  font-size: 0.8rem !important;
}

.font-size-normal {
  font-size: 1.1rem !important;
}

.font-size-medium {
  font-size: 1.5rem !important;
}

.font-size-large {
  font-size: 2.5rem !important;
}

/* Primereact */
.p-datascroller .p-datascroller-content {
  border: 0 !important;
  padding: 0 !important;
}

ul.p-datascroller-list > li {
  border-bottom: 1px solid var(--bg-color) !important;
}

ul.p-datascroller-list > li:last-child {
  border-bottom: 0 !important;
}

.p-dialog.p-component {
  border-radius: 2em !important;
}

.p-dialog-titlebar {
  border-radius: 2em 2em 0 0 !important;
}

.p-dialog-footer {
  border-radius: 0 0 2em 2em !important;
}

.p-accordion-content {
  border: 0 !important;
}

.p-fileupload-disabled .p-button {
  border: 0 !important;
  background-color: var(--dark-color) !important;
  color: #eeeeee !important;
}

.p-button {
  border: 0 !important;
  background-color: var(--light-color) !important;
}

.p-carousel .p-carousel-content .p-carousel-prev:hover, body .p-carousel .p-carousel-content .p-carousel-next:hover {
  color: white !important;
}

.p-button:enabled:focus,
.p-link:focus {
  box-shadow: unset !important;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
  border: 1px solid #a6a6a6 !important;
  background-color: var(--light-color) !important;
}

.p-inputtext:enabled:focus, body .p-inputtext:enabled:hover,
.p-checkbox .p-checkbox-box:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled).p-focus {
  border: 1px solid #a6a6a6 !important;
  box-shadow: unset !important;
}

.p-carousel .p-carousel-dots-container .p-carousel-dot-item.p-highlight .p-carousel-dot-icon {
  background-color: var(--dark-color) !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
  background-color: var(--dark-color) !important;
}

.p-accordion-content {
  background-color: var(--lightest-color) !important;
  padding: 1rem 0.5rem 1rem 0.5rem !important;
}

.p-accordion-tab, .p-accordion-header.p-disabled,
.p-datatable .p-sortable-column.p-highlight {
  background-color: var(--dark-color) !important;
}

.p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a,
.p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight a,
.p-tabview.p-tabview-left .p-tabview-nav li.p-highlight a,
.p-tabview.p-tabview-right .p-tabview-nav li.p-highlight a {
  background-color: var(--dark-color) !important;
  border-color: var(--dark-color) !important;
}

.p-dialog .p-dialog-content,
.p-tabview .p-tabview-panels {
  background-color: var(--lightest-color) !important;
  border-color: var(--lightest-color) !important;
}

.p-dialog .p-dialog-titlebar,
.p-dialog .p-dialog-footer {
  background-color: var(--bg-color) !important;
  border-color: var(--bg-color) !important;
}

.p-tabview .p-tabview-panels {
  padding: 1rem 0 0 0 !important;
}

.p-datatable .p-datatable-tbody > tr:nth-child(2n) > td {
  background-color: var(--bg-color) !important;
}

.p-datatable .p-datatable-tbody > tr:nth-child(2n+1) > td {
  background-color: var(--lightest-color) !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  color: var(--light-color) !important;
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: var(--bg-color) !important;
  color: var(--darkest-color) !important;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  border-color: var(--lightest-color) !important;
}

.p-dialog-title {
  color: var(--light-color) !important;
}

.p-tabview.p-tabview-top .p-tabview-nav li:not(.p-tabview-selected) a,
.p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-tabview-selected) a,
.p-tabview.p-tabview-left .p-tabview-nav li:not(.p-tabview-selected) a,
.p-tabview.p-tabview-right .p-tabview-nav li:not(.p-tabview-selected) a {
  background-color: var(--bg-color) !important;
  border-color: var(--bg-color) !important;
  color: var(--darkest-color) !important;
}

.p-dropdown, .p-inputtext, .p-dropdown-trigger {
  background-color: var(--light-color) !important;
  color: #ffffff !important;
  border-color: var(--lightest-color) !important;
}

.p-paginator {
  background-color: var(--lightest-color) !important;
  border: 0 !important
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--dark-color) !important;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background-color: var(--light-color) !important;
}

.p-datatable-auto-layout > .p-datatable-wrapper {
  overflow: initial !important;
}