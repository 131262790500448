
.cart-product {
    border-bottom: 1px solid #a1a1a1;
}

.cart-product:nth-last-of-type(2) {
    border-bottom: 2px solid var(--light-color);
}

.cart-product:nth-last-of-type(1) {
    border-bottom: 0;
}